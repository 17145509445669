/*-------------------------
  Helper Classes
-------------------------*/

.bg-color-dark {
    background-color: var(--color-dark);
}

.bg-color-light {
    background-color: var(--color-light);
}

.bg-color-adverse {
    background-color: #00a2b5;
}

.bg-color-adverse2 {
    background-color: #662e99;
}

.bg-color-mercury {
    background-color: var(--color-mercury);
}

.bg-color-ship-gray {
    background-color: var(--color-ship-gray);
}

// Color
.color-gray-4 {
    color: var(--color-gray-4);
}

// Opacity
.opacity-50 {
    opacity: 0.5;
}